<template>
  <div class="close-deal">
    <div class="flex">
      <div class="box">
        <label>{{ $t("properties.status.title") }}</label>
        <Select
          v-model="form.status"
          :options="[
            { name: $t('overall.not_specified'), value: null },
            { name: $t('clients.achieved'), value: 1 },
            { name: $t('clients.refused'), value: 0 },
          ]"
        />
      </div>
      <template v-if="form.status">
        <div class="element">
          <h2>{{ $t("clients.close_date") }}</h2>
          <Datepicker v-model="form.availability" :placeholder="$t('overall.add_date')" />
        </div>
        {{ form.property }}
        <Autocomplete
          :placeholder="$t('projects.add_property')"
          @save="$emit('addProperty', $event)"
          @search="$store.dispatch('properties/search', $event)"
          :options="$store.getters['properties/options']"
          :clearOnSave="true"
        />
        <div class="element">
          <h2>{{ $t("clients.sold_price") }}</h2>
          <div class="input-wrapper">
            <Input type="text" placeholder="100 000" v-model="form.selling_price" />
            <span class="unit">{{ $store.getters.getCurrency }}</span>
          </div>
        </div>

        <div class="element">
          <h2>{{ $t("clients.selling_price") }}</h2>
          <div class="input-wrapper">
            <Input type="text" placeholder="100 000" v-model="form.sold_price" />
            <span class="unit">{{ $store.getters.getCurrency }}</span>
          </div>
        </div>
      </template>
      <div class="box full-box">
        <h2>{{ $t("overall.note") }}</h2>
        <Textarea :value="form.note" @input="$emit('input', form)" :is-counter="true" :max="200" />
      </div>
    </div>
    <div class="box">
      <label>{{ $t("clients.archive_property") }}</label>
      <Select
        v-model="form.status"
        :options="[
          { name: $t('overall.do_not_archive'), value: 0 },
          { name: $t('clients.archive'), value: 1 },
        ]"
      />
    </div>
    <div class="box">
      <label>{{ $t("clients.mark_sold") }}</label>
      <Select
        v-model="form.status"
        :options="[
          { name: $t('clients.no_change'), value: null },
          { name: $t('properties.status.sold'), value: 0 },
          { name: $t('properties.status.booked'), value: 1 },
        ]"
      />
    </div>
    <div class="cta" v-on:click="$emit('closeDeal')">{{ $t("clients.close_deal") }}</div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ClientInquiry",
  props: ["details"],

  data() {
    return {
      form: {},
    };
  },
  methods: {},
  watch: {
    details: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val);
        if (val) {
          this.form = val;
        }
      },
    },
  },
  created() {},
});
</script>
